import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Config } from "../../site.config"

const MainAmz = props => {
  const { arg, val, session_id } = props

  return (
    <div
      style={{ maxWidth: "960px" }}
      className="grid grid-cols-1 gap-0 mx-auto my-0"
    >
      <div className="img_box">
        <StaticImage
          width={960}
          height={189}
          src={"../assets/images/amz/FforMEN_spA+_02_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_02_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={127}
          src={"../assets/images/amz/FforMEN_spA+_02_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_02_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={157}
          src={"../assets/images/amz/FforMEN_spA+_02_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_02_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={158}
          src={"../assets/images/amz/FforMEN_spA+_02_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_02_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={204}
          src={"../assets/images/amz/FforMEN_spA+_03_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_03_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={204}
          src={"../assets/images/amz/FforMEN_spA+_03_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_03_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={203}
          src={"../assets/images/amz/FforMEN_spA+_03_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_03_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={204}
          src={"../assets/images/amz/FforMEN_spA+_03_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_03_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={248}
          src={"../assets/images/amz/FforMEN_spA+_05_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_05_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={249}
          src={"../assets/images/amz/FforMEN_spA+_05_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_05_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={248}
          src={"../assets/images/amz/FforMEN_spA+_05_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_05_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={248}
          src={"../assets/images/amz/FforMEN_spA+_05_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_05_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={178}
          src={"../assets/images/amz/FforMEN_spA+_06_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_06_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={177}
          src={"../assets/images/amz/FforMEN_spA+_06_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_06_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={178}
          src={"../assets/images/amz/FforMEN_spA+_06_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_06_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={178}
          src={"../assets/images/amz/FforMEN_spA+_06_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_06_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={177}
          src={"../assets/images/amz/FforMEN_spA+_06_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_06_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={178}
          src={"../assets/images/amz/FforMEN_spA+_06_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_06_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={160}
          src={"../assets/images/amz/FforMEN_spA+_07_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_07_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={159}
          src={"../assets/images/amz/FforMEN_spA+_07_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_07_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={160}
          src={"../assets/images/amz/FforMEN_spA+_07_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_07_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={160}
          src={"../assets/images/amz/FforMEN_spA+_07_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_07_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={160}
          src={"../assets/images/amz/FforMEN_spA+_07_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_07_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={159}
          src={"../assets/images/amz/FforMEN_spA+_07_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_07_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={160}
          src={"../assets/images/amz/FforMEN_spA+_07_07.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_07_07.png"}
        />
      </div>
      <div className="img_box">
        <a href={Config.amzUrl} target="_blank" rel="noreferrer">
          <StaticImage
            width={960}
            height={266}
            src={"../assets/images/amz/FforMEN_spA+_08.png"}
            placeholder="blurred"
            quality="65"
            alt={"FforMEN_spA+_08.png"}
          />
        </a>
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={234}
          src={"../assets/images/amz/FforMEN_spA+_09_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_09_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={235}
          src={"../assets/images/amz/FforMEN_spA+_09_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_09_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={234}
          src={"../assets/images/amz/FforMEN_spA+_09_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_09_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={234}
          src={"../assets/images/amz/FforMEN_spA+_09_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_09_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={234}
          src={"../assets/images/amz/FforMEN_spA+_10_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_10_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={235}
          src={"../assets/images/amz/FforMEN_spA+_10_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_10_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={234}
          src={"../assets/images/amz/FforMEN_spA+_10_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_10_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={234}
          src={"../assets/images/amz/FforMEN_spA+_10_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_10_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={150}
          src={"../assets/images/amz/FforMEN_spA+_11_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_11_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={151}
          src={"../assets/images/amz/FforMEN_spA+_11_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_11_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={150}
          src={"../assets/images/amz/FforMEN_spA+_11_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_11_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={163}
          src={"../assets/images/amz/FforMEN_spA+_12_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_12_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={163}
          src={"../assets/images/amz/FforMEN_spA+_12_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_12_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={164}
          src={"../assets/images/amz/FforMEN_spA+_12_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_12_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={163}
          src={"../assets/images/amz/FforMEN_spA+_12_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_12_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={163}
          src={"../assets/images/amz/FforMEN_spA+_12_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spA+_12_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={292}
          src={"../assets/images/amz/FforMEN_spB+_02_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_02_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={292}
          src={"../assets/images/amz/FforMEN_spB+_02_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_02_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={292}
          src={"../assets/images/amz/FforMEN_spB+_02_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_02_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={292}
          src={"../assets/images/amz/FforMEN_spB+_02_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_02_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={214}
          src={"../assets/images/amz/FforMEN_spB+_03_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_03_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={214}
          src={"../assets/images/amz/FforMEN_spB+_03_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_03_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={213}
          src={"../assets/images/amz/FforMEN_spB+_03_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_03_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={214}
          src={"../assets/images/amz/FforMEN_spB+_03_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_03_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={285}
          src={"../assets/images/amz/FforMEN_spB+_04_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_04_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={285}
          src={"../assets/images/amz/FforMEN_spB+_04_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_04_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={285}
          src={"../assets/images/amz/FforMEN_spB+_04_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_04_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={285}
          src={"../assets/images/amz/FforMEN_spB+_04_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_04_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={220}
          src={"../assets/images/amz/FforMEN_spB+_07_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={220}
          src={"../assets/images/amz/FforMEN_spB+_07_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={219}
          src={"../assets/images/amz/FforMEN_spB+_07_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={220}
          src={"../assets/images/amz/FforMEN_spB+_07_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={220}
          src={"../assets/images/amz/FforMEN_spB+_07_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={220}
          src={"../assets/images/amz/FforMEN_spB+_07_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={219}
          src={"../assets/images/amz/FforMEN_spB+_07_07.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_07.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={220}
          src={"../assets/images/amz/FforMEN_spB+_07_08.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_08.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={959}
          height={220}
          src={"../assets/images/amz/FforMEN_spB+_07_09.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_spB+_07_09.png"}
        />
      </div>
      <div className="img_box">
        <a href={Config.amzUrl} target="_blank" rel="noreferrer">
          <StaticImage
            width={959}
            height={310}
            src={"../assets/images/amz/FforMEN_spB+_09.png"}
            placeholder="blurred"
            quality="65"
            alt={"FforMEN_spB+_09.png"}
          />
        </a>
      </div>

      <div className="img_box">
        <StaticImage
          width={960}
          height={198}
          src={"../assets/images/amz/FforMEN_pcB+_01_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_01_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={198}
          src={"../assets/images/amz/FforMEN_pcB+_01_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_01_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={197}
          src={"../assets/images/amz/FforMEN_pcB+_01_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_01_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={198}
          src={"../assets/images/amz/FforMEN_pcB+_01_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_01_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={198}
          src={"../assets/images/amz/FforMEN_pcB+_01_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_01_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={347}
          src={"../assets/images/amz/FforMEN_pcB+_02_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_02_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={347}
          src={"../assets/images/amz/FforMEN_pcB+_02_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_02_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={348}
          src={"../assets/images/amz/FforMEN_pcB+_02_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_02_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={347}
          src={"../assets/images/amz/FforMEN_pcB+_02_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_02_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={347}
          src={"../assets/images/amz/FforMEN_pcB+_02_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_02_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={192}
          src={"../assets/images/amz/FforMEN_pcB+_03_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_03_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={191}
          src={"../assets/images/amz/FforMEN_pcB+_03_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_03_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={192}
          src={"../assets/images/amz/FforMEN_pcB+_03_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_03_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={207}
          src={"../assets/images/amz/FforMEN_pcB+_04_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_04_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={207}
          src={"../assets/images/amz/FforMEN_pcB+_04_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_04_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={208}
          src={"../assets/images/amz/FforMEN_pcB+_04_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_04_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={207}
          src={"../assets/images/amz/FforMEN_pcB+_04_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_04_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={207}
          src={"../assets/images/amz/FforMEN_pcB+_04_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_04_05.png"}
        />
      </div>
      <div className="img_box">
        <a href={Config.amzUrl} target="_blank" rel="noreferrer">
          <StaticImage
            width={960}
            height={207}
            src={"../assets/images/amz/FforMEN_pcB+_04_06.png"}
            placeholder="blurred"
            quality="65"
            alt={"FforMEN_pcB+_04_06.png"}
          />
        </a>
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={152}
          src={"../assets/images/amz/FforMEN_pcB+_05_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_05_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={153}
          src={"../assets/images/amz/FforMEN_pcB+_05_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_05_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={152}
          src={"../assets/images/amz/FforMEN_pcB+_05_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_05_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={153}
          src={"../assets/images/amz/FforMEN_pcB+_05_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_05_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={152}
          src={"../assets/images/amz/FforMEN_pcB+_05_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_05_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={153}
          src={"../assets/images/amz/FforMEN_pcB+_05_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_05_06.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={152}
          src={"../assets/images/amz/FforMEN_pcB+_05_07.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_05_07.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={143}
          src={"../assets/images/amz/FforMEN_pcB+_06_01.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_06_01.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={142}
          src={"../assets/images/amz/FforMEN_pcB+_06_02.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_06_02.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={143}
          src={"../assets/images/amz/FforMEN_pcB+_06_03.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_06_03.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={143}
          src={"../assets/images/amz/FforMEN_pcB+_06_04.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_06_04.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={142}
          src={"../assets/images/amz/FforMEN_pcB+_06_05.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_06_05.png"}
        />
      </div>
      <div className="img_box">
        <StaticImage
          width={960}
          height={143}
          src={"../assets/images/amz/FforMEN_pcB+_06_06.png"}
          placeholder="blurred"
          quality="65"
          alt={"FforMEN_pcB+_06_06.png"}
        />
      </div>
      <style jsx="true">
        {`
          .img_box > div {
            overflow: hidden;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            width: 100%;
            margin: 0 auto -1px;
            padding: 0;
          }
          .img_box > div > img {
            width: 100%;
            max-width: 100%;
          }
        `}
      </style>
    </div>
  )
}
export default MainAmz
